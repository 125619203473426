import React, { memo } from 'react';
import { motion } from 'framer-motion';

const BookmarkPlusIcon = memo(() => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    variants={{
      initial: { fill: '#999999', scale: 1 },
      hover: { fill: '#4C4C4C', scale: 1.1 }
    }}
    transition={{ duration: 0.2 }}
  >
    <motion.path fillRule="evenodd" clipRule="evenodd" d="M7.91956 2C7.38541 1.99999 6.93956 1.99998 6.57533 2.02974C6.19545 2.06078 5.83879 2.12789 5.50153 2.29973C4.98408 2.56338 4.56339 2.98408 4.29973 3.50153C4.12789 3.83879 4.06078 4.19545 4.02974 4.57533C3.99998 4.93955 3.99999 5.38538 4 5.91951V18.9878C3.99998 19.4516 3.99997 19.8507 4.02879 20.1656C4.05755 20.4798 4.12334 20.849 4.35936 21.158C4.67233 21.5676 5.15002 21.8185 5.66496 21.8435C6.0533 21.8624 6.3946 21.707 6.66956 21.5523C6.94516 21.3972 7.27366 21.1706 7.6555 20.9073L10.6091 18.8703C11.3145 18.3838 11.511 18.2626 11.7011 18.2158C11.8974 18.1674 12.1026 18.1674 12.299 18.2158C12.4891 18.2626 12.6855 18.3838 13.391 18.8703L16.3445 20.9073C16.7264 21.1706 17.0549 21.3972 17.3304 21.5523C17.6054 21.7069 17.9467 21.8624 18.335 21.8435C18.85 21.8185 19.3277 21.5676 19.6406 21.158C19.8767 20.849 19.9425 20.4798 19.9712 20.1656C20 19.8507 20 19.4517 20 18.9879V5.91955C20 5.38546 20 4.93953 19.9703 4.57533C19.9392 4.19545 19.8721 3.83879 19.7003 3.50153C19.4366 2.98408 19.0159 2.56338 18.4985 2.29973C18.1612 2.12789 17.8046 2.06078 17.4247 2.02974C17.0604 1.99998 16.6146 1.99999 16.0804 2H7.91956ZM12 6C12.4142 6 12.75 6.33579 12.75 6.75V9.25H15.25C15.6642 9.25 16 9.58579 16 10C16 10.4142 15.6642 10.75 15.25 10.75H12.75V13.25C12.75 13.6642 12.4142 14 12 14C11.5858 14 11.25 13.6642 11.25 13.25V10.75H8.75C8.33579 10.75 8 10.4142 8 10C8 9.58579 8.33579 9.25 8.75 9.25H11.25V6.75C11.25 6.33579 11.5858 6 12 6Z" />
  </motion.svg>
));

export default BookmarkPlusIcon;