/* App.js */

import React from 'react';
import SquizzeButton from './SquizzeButton'; // Adjust the path if necessary
import './App.css'; // We'll create this file for App-specific styles

function App() {
  return (
    <div className="app-container">
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
        <SquizzeButton />
      </div>
    </div>
  );
}

export default App;