import React from 'react';
import { motion } from 'framer-motion';

const AddToBasketIcon = () => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    variants={{
      initial: { fill: '#999999', scale: 1 },
      hover: { fill: '#4C4C4C', scale: 1.1 }
    }}
    transition={{ duration: 0.2 }}
  >
    <motion.path d="M8.52855 3.05384C8.91313 3.20768 9.1002 3.64415 8.94636 4.02874L7.35786 8H16.6421L15.0536 4.02874C14.8998 3.64415 15.0869 3.20768 15.4715 3.05384C15.856 2.90001 16.2925 3.08707 16.4464 3.47166L18.2577 8H20.0601C21.1465 8 21.9704 8.97952 21.7842 10.0498L21.1545 13.671C20.0764 12.6361 18.6125 12 17 12C13.6863 12 11 14.6863 11 18C11 19.0929 11.2922 20.1175 11.8027 21H6.43124C5.09425 21 3.95099 20.0384 3.72191 18.7212L2.21385 10.0498C2.0277 8.97953 2.85158 8 3.93797 8H5.74231L7.55365 3.47166C7.70748 3.08707 8.14396 2.90001 8.52855 3.05384Z" />
    <motion.path fillRule="evenodd" clipRule="evenodd" d="M17 14C17.4142 14 17.75 14.3358 17.75 14.75V17.25H20.25C20.6642 17.25 21 17.5858 21 18C21 18.4142 20.6642 18.75 20.25 18.75H17.75V21.25C17.75 21.6642 17.4142 22 17 22C16.5858 22 16.25 21.6642 16.25 21.25V18.75H13.75C13.3358 18.75 13 18.4142 13 18C13 17.5858 13.3358 17.25 13.75 17.25H16.25V14.75C16.25 14.3358 16.5858 14 17 14Z" />
  </motion.svg>
);

export default AddToBasketIcon;