import React, { useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import AddToBasketIcon from './AddToBasketIcon';
import BookmarkPlusIcon from './BookmarkPlusIcon';
import './SquizzeButton.css';

const SquizzeButton = () => {
  const [isBookmarkHovered, setIsBookmarkHovered] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [isSaved, setIsSaved] = useState(false);  // New state for bookmark

  const fireWebhook = useCallback((action) => {
    const timestamp = new Date().toISOString().replace(/[-:]/g, '_').replace(/\..+/, '');

    fetch('https://n8n.stepanprokop.com/webhook/shopping-button', {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'X-Timestamp': timestamp
      },
      body: JSON.stringify({ action }),
    })
      .then(() => {
        if (action === 'add_to_basket') {
          setIsAdded(true);
          setTimeout(() => setIsAdded(false), 1000);
        } else if (action === 'save_to_fav') {
          setIsSaved(true);
          setTimeout(() => setIsSaved(false), 1000);
        }
      })
      .catch((error) => console.error('Error:', error));
  }, []);

  const labelVariants = {
    initial: { opacity: 0, y: -10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 10 },
  };

  return (
    <div className="cta-button">
      <motion.div 
        className="basket-button"
        style={{ width: isBookmarkHovered ? '30px' : '179px' }}
        transition={{ duration: 0.3 }}
        onClick={() => fireWebhook('add_to_basket')}
      >
        <AddToBasketIcon />
        <AnimatePresence mode="wait">
          <motion.span
            key={isAdded ? 'added' : 'add'}
            variants={labelVariants}
            initial="initial"
            animate="animate"
            exit="exit" 
            transition={{ duration: 0.1 }}
          >
            {isAdded ? "Added!" : "Add to basket"}
          </motion.span>
        </AnimatePresence>
      </motion.div>
      <div className="separator" />
      <motion.div 
        className="bookmark-button" 
        style={{ width: isBookmarkHovered ? '179px' : '30px' }}
        onHoverStart={() => setIsBookmarkHovered(true)}
        onHoverEnd={() => setIsBookmarkHovered(false)}
        transition={{ duration: 0.3 }}
        onClick={() => fireWebhook('save_to_fav')}
      >
        <BookmarkPlusIcon />
        <AnimatePresence mode="wait">
          <motion.span
            key={isSaved ? 'saved' : 'save'}
            variants={labelVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.1 }}
          >
            {isSaved ? "Saved!" : "Save to Fav"}
          </motion.span>
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default SquizzeButton;